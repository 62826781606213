<template>
  <article class="Inspiration-featured-content">
    <div class="Inspiration-featured-content__content">
      <ux-atoms-typo :text="label" as="p" class="Inspiration-featured-content__label" color="light" v-if="label" variant="text-small" />
      <ux-atoms-typo as="h2" class="Inspiration-featured-content__title" color="dark" v-if="title" variant="expressive-heading-03">
        {{ title }}
      </ux-atoms-typo>
      <div class="Inspiration-featured-content__link-container">
        <ux-atoms-link
          :classic="false"
          :to="href"
          @click="gtmLoyaltyBannerEvent"
          class="Inspiration-featured-content__link Button Button--outlined Button--m"
        >
          {{ $t('ux.molecules.inspirationFeaturedContent.link') }}
        </ux-atoms-link>
      </div>
    </div>
    <div class="Inspiration-featured-content__media">
      <ux-atoms-image :alt="alt ?? title" :media="media" :src="src" :src-set="srcSet" fetch-priority="high" loading="eager" />
    </div>
  </article>
</template>
<script setup lang="ts">
import { mediaType } from '~/components/ux/atoms/Image/types';
import { gtmEvent } from '~/helpers/gtm';

export interface InspirationFeaturedContentProps {
  alt?: string;
  href?: string;
  label?: string;
  media?: mediaType;
  src?: string;
  srcSet?: null | string;
  title?: string;
}

const props = defineProps<InspirationFeaturedContentProps>();

const gtmLoyaltyBannerEvent = () => {
  gtmEvent('bloc_interact', {
    bloc_interaction: `discover offer - ${props.title}`,
    bloc_name: 'offer banner',
    pagename: 'restaurantsandbars::home'
  });
};
</script>

<style lang="scss" scoped>
@use 'InspirationFeaturedContent.scss';
</style>
