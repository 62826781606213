<template>
  <div
    :aria-describedby="ariaDescribedBy"
    :class="['Slider-card-restaurant', { 'Slider-card-restaurant--homepage': pageName?.includes('home') }]"
    aria-roledescription="carousel"
  >
    <div class="Slider-card-restaurant-title" v-if="(title || href) && cards.length">
      <ux-atoms-typo :id="ariaDescribedBy" as="h2" color="dark" v-if="title" variant="expressive-heading-04">
        {{ title }}
      </ux-atoms-typo>

      <ux-atoms-typo as="p" v-if="href && linkText">
        <ux-atoms-link :to="href" class="Slider-card-restaurant__link-text">
          {{ linkText }}
          <ux-atoms-icon name="arrow-right" size="s" />
        </ux-atoms-link>
      </ux-atoms-typo>
    </div>
    <div class="Slider-card-restaurant__container" v-if="cards">
      <Carousel
        :breakpoints="getCarouselSettings"
        :id="uniqueId"
        :items-to-scroll="1"
        :items-to-show="1.33"
        aria-live="off"
        class="Slider-card-restaurant__carousel"
        ref="carousel"
      >
        <Slide
          :class="[{ 'Slider-card-restaurant__slide--favorites': card.variant === VariantType.Favorites }]"
          :key="card.title"
          class="Slider-card-restaurant__slide"
          v-for="card in cards"
        >
          <ux-molecules-card-restaurant v-bind="card" :hide-availabilities="hideAvailabilities" @card-restaurant::interact="cardEvent" />
        </Slide>
        <template #addons>
          <div class="Slider-card-restaurant__nav" v-if="displayNavigation">
            <ux-atoms-button
              :aria-controls="uniqueId"
              :disabled="!canGoBackward"
              :label="$t('ux.molecules.sliderCardRestaurant.prev')"
              @click="prev"
              size="s"
              variant="control"
            >
              <ux-atoms-icon name="chevron-left" size="m" />
            </ux-atoms-button>
            <ux-atoms-button
              :aria-controls="uniqueId"
              :disabled="!canGoForward"
              :label="$t('ux.molecules.sliderCardRestaurant.next')"
              @click="next"
              size="s"
              variant="control"
            >
              <ux-atoms-icon name="chevron-right" size="m" />
            </ux-atoms-button>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script setup lang="ts">
// Partial reuse from packages/front/src/components/ux/molecules/WlnsSlideShow/WlnsSlideShow.vue
import { Carousel, Slide } from 'vue3-carousel';

import { VariantType, cardType } from '~/components/ux/molecules/CardRestaurant/types';
import { breakpoints } from '~/helpers/breakpoints';
import { gtmEvent } from '~/helpers/gtm';

export interface SliderCardRestaurantProps {
  ariaDescribedBy?: string;
  blockName?: string;
  cards: cardType[];
  hideAvailabilities?: boolean;
  href?: null | string;
  linkText?: string;
  pageName?: string;
  title?: null | string;
}

const props = defineProps<SliderCardRestaurantProps>();

const uniqueId = useId();

const getCarouselSettings = {
  [breakpoints.lg]: {
    itemsToScroll: 4,
    itemsToShow: 4,
    snapAlign: 'start'
  },
  [breakpoints.md]: {
    itemsToScroll: 2,
    itemsToShow: 2.33,
    snapAlign: 'start'
  },
  [breakpoints.sm]: {
    itemsToScroll: 1,
    itemsToShow: 1.33,
    snapAlign: 'start'
  }
};

const carousel = ref();

const currentSlide = computed(() => carousel?.value?.data?.currentSlide.value ?? 0);

const canGoBackward = computed(() => {
  if (props.cards.length <= 1) {
    return false;
  }
  return currentSlide.value !== 0;
});

const canGoForward = computed(() => {
  return currentSlide.value + carousel?.value?.data?.config.itemsToShow < props.cards.length;
});

const displayNavigation = computed(() => {
  return carousel?.value?.data?.config.itemsToShow < props.cards.length;
});

const prev = () => {
  if (carousel.value && canGoBackward.value) {
    carousel.value.prev();
  }
};

const next = () => {
  if (carousel.value && canGoForward.value) {
    carousel.value.next();
  }
};

const cardEvent = (cardTitle: string) => {
  gtmEvent('bloc_interact', {
    bloc_interaction: `select - ${cardTitle.toLowerCase()}`,
    bloc_name: props.blockName,
    pagename: props.pageName
  });
};
</script>
<style lang="scss" scoped>
@use 'SliderCardRestaurant.scss';
</style>
