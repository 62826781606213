<template>
  <ux-molecules-hero-homepage :media="country?.coverMedia" :title="country?.mainTitle" />

  <section class="Layout" v-if="editorialTopHomepage">
    <ux-molecules-inspiration-featured-content v-bind="cardEditorialTopHomepage" />
  </section>

  <section class="Layout" v-if="favoritesRestaurants">
    <ux-molecules-slider-card-restaurant
      :aria-described-by="favoritesRestaurants.title"
      :cards="favoritesRestaurantsCards as unknown as cardType[]"
      :hide-availabilities="true"
      :title="favoritesRestaurants.title"
      :unique-id="favoritesRestaurants.title?.toLocaleLowerCase + '-id'"
      block-name="favorites restaurants"
      page-name="restaurantsandbars::home"
    />
  </section>

  <section class="Layout" v-if="isLoading">
    <ux-atoms-loader class="pt-8" />
  </section>
  <section class="Layout" v-if="restaurantsCards && restaurantsCards?.length > 0">
    <ux-atoms-link href="#after-sliders" skip v-if="sliderLink()">{{ $t('pages.country.skipAfterSliders') }}</ux-atoms-link>
    <ux-molecules-slider-card-restaurant
      :cards="restaurantsCards as unknown as cardType[]"
      :hide-availabilities="true"
      :href="sliderLink()"
      :link-text="$t('pages.country.discoverMoreRestaurants')"
      :title="$t('pages.country.restaurantsCloseToMe')"
      aria-described-by="relatedTitleId"
      block-name="around me"
      page-name="restaurantsandbars::home"
      v-if="sliderLink()"
    />
  </section>

  <template :key="thematic?.thematic?.code as string" v-for="thematic in nearbyThematicRestaurants">
    <section class="Layout" v-if="thematic?.results?.length">
      <ux-atoms-link href="#after-sliders" skip v-if="thematic?.results && sliderLink(thematic?.thematic)">
        {{ $t('pages.country.skipAfterSliders') }}
      </ux-atoms-link>
      <ux-molecules-slider-card-restaurant
        :aria-described-by="`${thematic?.thematic?.code}-aria`"
        :cards="thematic?.results?.map((i) => getRestaurantsCardDetails(i)) as unknown as cardType[]"
        :hide-availabilities="true"
        :href="sliderLink(thematic?.thematic)"
        :link-text="$t('pages.country.discoverMoreRestaurants')"
        :title="thematic?.thematic?.title"
        :unique-id="thematic?.thematic?.code"
        block-name="specific service"
        page-name="restaurantsandbars::home"
        v-if="thematic?.results && sliderLink(thematic?.thematic)"
      />
    </section>
  </template>
  <section id="after-sliders" tabindex="-1"></section>

  <ux-molecules-inspiration-insert :cards="inspirationCards" has-title />

  <section class="Layout" v-if="selectedLoyaltyBanner">
    <ux-molecules-loyalty-banner
      :display-cta="true"
      :is-accor-plus="selectedLoyaltyBanner.program === LoyaltyProgram.AccorPlus"
      :loyalty-banner="selectedLoyaltyBanner"
      :variant="LoyaltyVariant.Home"
    />
  </section>

  <section class="Layout">
    <ux-molecules-country-seo-insert
      :country-name="country?.name"
      :country-seo-items="data?.countryBaseInformation?.seoItems"
      v-if="data?.countryBaseInformation?.seoItems?.length"
    />
    <ux-organismes-accordion-list :cities-list="citiesData" :countries-list="countriesData" :country-name="country?.name" :country-slug="slug" />
  </section>

  <Teleport to="body">
    <ux-molecules-modal
      :has-header="false"
      button-no-border
      button-position="right"
      has-label-id
      size="small"
      v-if="isModalOpen"
      v-model="isModalOpen"
    >
      <ux-atoms-typo as="p" color="dark" variant="text-regular">
        <i18n-t keypath="pages.country.inspirationNotFound">
          <ux-atoms-link :to="localePath(t('pages.country.inspirationLink'))" target="_blank" underline>
            {{ t('pages.country.inspirationLinkText') }}
          </ux-atoms-link>
        </i18n-t>
      </ux-atoms-typo>
    </ux-molecules-modal>
  </Teleport>
</template>

<script lang="ts" setup>
import { cardType } from '~/components/ux/molecules/CardRestaurant/types';
import { LoyaltyProgram, LoyaltyType, LoyaltyUserStatus, LoyaltyVariant } from '~/core';
import {
  CountryBaseInformation,
  CountryBySlugQuery,
  EditorialContent,
  Restaurant,
  RestaurantSearchThematic,
  // eslint-disable-next-line camelcase
  Thematic_Type,
  useCitiesByCountrySlugAsyncData,
  useCountriesAsyncData,
  useCountryBaseInformationAsyncData,
  useCountryBySlugLazyQuery,
  useLoyaltyBannersAsyncData
} from '~/graphql';
import { gtmPageAndUser } from '~/helpers/gtm';
import { sortAlphabetically } from '~/helpers/sort';

const route = useRoute();
const router = useRouter();
const { locale, t } = useI18n();
const localePath = useLocalePath();
const geolocationStore = useGeolocationStore();
const userStore = useUserStore();
const asyncData = ref<CountryBySlugQuery>();
const isLoading = ref<boolean>(false);
const isModalOpen = ref<boolean>(false);
const { baseCountry } = useCountry();

const slug = computed(() => (route.params.country ? (route.params.country as string) : ''));

const { searchParams } = useSearchRestaurant();

const { data, error } = await useCountryBaseInformationAsyncData({ slug: slug.value });

useAsyncDataError(error, 'country-slug');

const { data: LoyaltyBannerData } = await useLoyaltyBannersAsyncData({ type: LoyaltyType.Home });

const queryCountry = async () => {
  if (userStore.userContext.userCity && userStore.userContext.userCountry) {
    isLoading.value = true;
    const { load, onResult } = useCountryBySlugLazyQuery({
      date: searchParams.value.date,
      groupSize: searchParams.value.groupSize,
      slug: slug.value,
      userCity: userStore.userContext.userCity.toLowerCase(), // default value has an uppercase first letter and is rejected by query
      userCountry: userStore.userContext.userCountry.toLowerCase() // default value has an uppercase first letter and is rejected by query
    });

    onResult((response) => {
      if (response.loading) return;
      // store new country data
      asyncData.value = response.data;
      isLoading.value = false;
    });

    await load();
  }
};

const editorialTopHomepage = computed<CountryBaseInformation['editorialTopHomepage']>(() => data.value?.countryBaseInformation?.editorialTopHomepage);

const cardEditorialTopHomepage = computed(() => {
  const slugEditorial = editorialTopHomepage.value?.slugs?.[locale.value as keyof typeof editorialTopHomepage.value.slugs] ?? '';
  return {
    alt: editorialTopHomepage.value?.coverImage?.alt,
    href: localePath(`/${editorialTopHomepage.value?.variant?.toLocaleLowerCase()}/${slugEditorial}`),
    label: editorialTopHomepage.value?.variant,
    media: editorialTopHomepage.value?.coverImage,
    src: editorialTopHomepage.value?.coverImage?.src,
    srcSet: editorialTopHomepage.value?.coverImage?.srcSet,
    title: editorialTopHomepage.value?.title
  };
});

const inspirationCards = computed(() => (data.value?.countryBaseInformation?.inspiration as EditorialContent[]) ?? []);

const country = computed(() => asyncData.value?.countryBySlug || data.value?.countryBaseInformation);

const loyaltyBanners = computed(() => LoyaltyBannerData.value?.loyaltyBanner);

const selectedLoyaltyBanner = computed(() =>
  loyaltyBanners.value
    ?.filter((loyaltyBanner) =>
      userStore.isLogged ? loyaltyBanner?.userStatus === LoyaltyUserStatus.LOGGED_IN : loyaltyBanner?.userStatus === LoyaltyUserStatus.LOGGED_OUT
    )
    .find((loyaltyBanner) => {
      if (userStore.APZone) {
        return loyaltyBanner?.program === LoyaltyProgram.AccorPlus && loyaltyBanner?.type === LoyaltyType.Home;
      }

      return loyaltyBanner?.program === LoyaltyProgram.All && loyaltyBanner?.type === LoyaltyType.Home;
    })
);

const nearbyThematicRestaurants = computed(() => {
  if (country.value?.__typename === 'Country') {
    return country.value?.nearbyThematicRestaurants;
  }

  return [];
});

const favoritesRestaurants = computed(() => data.value?.countryBaseInformation?.favoritesRestaurants);

const favoritesRestaurantsCards = computed(() => {
  return favoritesRestaurants?.value?.restaurants?.map((restaurant) => ({
    ...getRestaurantsCardDetails(restaurant),
    variant: 'favorites'
  }));
});

const { data: cities } = await useCitiesByCountrySlugAsyncData({
  countrySlug: slug.value
});

if (country.value) {
  baseCountry.value = slug.value;
}
const citiesData = computed(() => sortAlphabetically(cities.value?.citiesByCountrySlug));

const { data: countries } = await useCountriesAsyncData();

const countriesData = computed(() => sortAlphabetically(countries.value?.countries));

useRedirectIfEmpty(country.value);

const setI18nParams = useSetI18nParams();

setI18nParams({
  en: { country: country.value?.translatedSlug?.en },
  fr: { country: country.value?.translatedSlug?.fr }
});

const getRestaurantsCardDetails = (restaurant?: Restaurant | null) => {
  if (!restaurant) return;

  return {
    address: `${restaurant?.city} ${restaurant?.zipCode}`,
    alt: `${t('ux.molecules.sliderCardRestaurant.altCardImage', { restaurant: restaurant?.name })}`,
    // Event if we have some availabilities from graphql cache,
    // we don't want to display them on country page, that's why here we set it to undefined
    availabilities: undefined,
    averageRating: restaurant?.averageRating,
    bookingWidgetUrl: restaurant?.bookingWidgetUrl,
    currency: restaurant?.currency,
    facetCodes: restaurant?.facetCodes,
    foodType: restaurant?.foodType,
    href: localePath(`/restaurant/${restaurant?.id}`),
    id: restaurant?.id,
    identifiers: restaurant?.identifiers,
    medias: restaurant?.medias,
    partnerId: restaurant?.partnerId,
    phone: restaurant?.phonePrefix && restaurant.phone ? `+${restaurant?.phonePrefix}${restaurant?.phone}` : undefined,
    price: restaurant?.averagePrice,
    reviewCount: restaurant?.reviewCount,
    src: restaurant?.imageUrl,
    stars: restaurant?.star,
    title: restaurant?.name
  };
};

const restaurantsCards = computed(() => {
  if (country.value?.__typename === 'Country') {
    return country?.value?.nearbyRestaurants?.results?.map((i) => getRestaurantsCardDetails(i));
  }
});

const cityLink = computed(() => {
  return userStore.userContext
    ? `${localePath({
        name: 'country-city-map',
        params: {
          city: userStore.userContext?.userCity?.toLowerCase(),
          country: userStore.userContext?.userCountry?.toLowerCase()
        }
      })}`
    : undefined;
});

const poiLink = computed(() => {
  const { lat, lon } = geolocationStore;
  return lat !== null && lon !== null
    ? `${localePath({
        name: 'point-of-interest-slug-map',
        params: { slug: `${lat},${lon}` }
      })}`
    : undefined;
});

const sliderLink = (thematic?: RestaurantSearchThematic | null) => {
  if (country.value?.__typename === 'Country') {
    const thematicCode = thematic?.code === 'brunch' ? 'br' : thematic?.code;
    const thematicType = thematic?.type;

    return `${country.value.doesCityExist ? cityLink.value : poiLink.value}${
      thematicType &&
      thematicCode &&
      // eslint-disable-next-line camelcase
      ((thematicCode === 'br' && thematicType === Thematic_Type.Thematic) ||
        // eslint-disable-next-line camelcase
        (thematicCode !== 'br' && thematicType !== Thematic_Type.FoodPreferences))
        ? '?' + thematicType + '=' + thematicCode
        : ''
    }`;
  }
  return null;
};

const pageEvent = () => {
  gtmPageAndUser({
    brandcontext: 'restaurantsandbars',
    category: 'restaurantsandbars',
    clienttype: 'B2C',
    countrymarket: 'fr',
    currencycode: 'EUR',
    dynamic_datas_ready: true,
    pagename: 'restaurantsandbars::home',
    pagetype: 'restaurantsandbars',
    sub_category_level1: 'home',
    tarsmerchantid: 'seo-maps-FR-A064',
    tarssourceid: 'aw-cen',
    uamerchantid: 'REFERRALS',
    uasourceid: 'REFERRALS'
  });
};

watch(
  () => userStore.userContext,
  (value) => {
    if (value) {
      queryCountry();
    }
  },
  { immediate: true }
);

whenever(
  () => userStore.userLoadDone,
  () => {
    pageEvent();
  }
);

onMounted(() => {
  if (route.hash === '#info-modal') {
    isModalOpen.value = true;
    router.replace({ hash: '' });
  }
  if (userStore.userLoadDone) {
    pageEvent();
  }
});

useHead(() => ({
  meta: [
    {
      content: country.value?.metaDescription ?? t('pages.country.description', { countryName: country.value?.name ?? '' }),
      name: 'description'
    }
  ],
  title: country.value?.metaTitle ?? t('pages.country.title', { countryName: country.value?.name ?? '' })
}));
</script>
