<template>
  <div class="Accordion-list">
    <ux-molecules-accordion
      :title="$t('pages.country.citiesDestination', { countryName })"
      @is-hidden="(value) => handleAriaHidden('city', value)"
      is-collapsable
    >
      <template #content>
        <ul :aria-hidden="ariaHidden.city" class="Accordion-list__container">
          <li :key="`city-${city?.name}`" class="Accordion-list__container-item" v-for="city in citiesList">
            <ux-atoms-link :href="localePath(`/${countrySlug}/${city?.slug}/map`)" :text="city?.name" underline />
          </li>
        </ul>
      </template>
    </ux-molecules-accordion>
    <ux-molecules-accordion :title="$t('pages.country.countries')" @is-hidden="(value) => handleAriaHidden('country', value)" is-collapsable>
      <template #content>
        <ul :aria-hidden="ariaHidden.country" class="Accordion-list__container">
          <li :key="`country-${countryData?.name}`" class="Accordion-list__container-item" v-for="countryData in countriesList">
            <ux-atoms-link :href="localePath(`/${countryData?.slug}`)" :text="countryData?.name" underline />
          </li>
        </ul>
      </template>
    </ux-molecules-accordion>
  </div>
</template>
<script setup lang="ts">
import { City, Country } from '~/graphql';

export interface AccordionProps {
  citiesList?: City[];
  countriesList?: Country[];
  countryName?: null | string;
  countrySlug?: string;
}

withDefaults(defineProps<AccordionProps>(), {
  citiesList: undefined,
  countriesList: undefined,
  countryName: undefined,
  countrySlug: undefined
});

const localePath = useLocalePath();

const ariaHidden = ref({
  city: false,
  country: false
});

const handleAriaHidden = (type: 'city' | 'country', value: boolean) => {
  ariaHidden.value[type] = value;
};
</script>
<style scoped lang="scss">
@use 'AccordionList.scss';
</style>
