<template>
  <div class="Country-seo-insert">
    <ux-atoms-typo :text="$t('pages.country.about', { countryName })" as="h3" color="grey-1" variant="expressive-heading-05" />
    <div class="Country-seo-insert__item-container">
      <div :key="item?.title!" class="Country-seo-insert__item" v-for="item in countrySeoItemsFiltered">
        <ux-atoms-typo :text="item?.title" as="h4" color="dark" variant="text-regular" />
        <ux-atoms-typo :text="item?.content" as="p" color="light" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
export interface CountrySeoInsertProps {
  countryName?: null | string;
  countrySeoItems?: ({ content?: null | string; title?: null | string } | null)[] | null;
}

const props = withDefaults(defineProps<CountrySeoInsertProps>(), {
  countryName: undefined,
  countrySeoItems: undefined
});

const countrySeoItemsFiltered = computed(() => props.countrySeoItems?.filter((item) => item?.title && item?.content));
</script>

<style lang="scss" scoped>
@use './CountrySeoInsert.scss';
</style>
