<template>
  <div class="Accordion">
    <template v-if="isCollapsable">
      <ux-atoms-typo :aria-label="title" :text="title" as="h2" class="Accordion__title" color="grey-1" variant="expressive-heading-05">
        <button
          :aria-controls="`Accordion__content-${accordionId}`"
          :aria-expanded="isOpen"
          :id="`Accordion__trigger-${accordionTriggerId}`"
          @click="Toggle"
          class="Accordion__trigger"
          type="button"
        >
          <span class="Accordion__button-title">{{ title }}</span>
          <ux-atoms-icon class="Accordion__icon" name="chevron-down" size="l" />
        </button>
      </ux-atoms-typo>
    </template>
    <template v-else>
      <ux-atoms-typo :aria-label="title" :text="title" as="h2" />
    </template>
    <div
      :aria-labelledby="`Accordion__trigger-${accordionTriggerId}`"
      :id="`Accordion__content-${accordionId}`"
      class="Accordion__content"
      role="region"
      v-show="isOpen"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
export interface AccordionProps {
  isCollapsable: boolean;
  title: string;
}

withDefaults(defineProps<AccordionProps>(), {
  isCollapsable: false,
  title: undefined
});

interface Emits {
  (event: 'isHidden', value: boolean): void;
}

const emits = defineEmits<Emits>();

const accordionId = useId();
const accordionTriggerId = useId();

const isOpen = ref(false);

const Toggle = () => {
  const newValue = !isOpen.value;
  isOpen.value = newValue;
  emits('isHidden', !newValue);
};
</script>
<style scoped lang="scss">
@use 'Accordion.scss';
</style>
